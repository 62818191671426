/** Road **/

import React from "react";
import { gsap } from "gsap"
import FullscreenVideo from "../../components/media/fullscreen-video"
import { preloadImages, preloadAudio } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { showText, hideText, positionText, resetAnimationDelay } from "../../helpers"


let globalState, data, preloadData;

class IntroPage2 extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[2];
    preloadData = globalState.assets[3];

    //refs
    this.roadVideoRef = this.planeLandingVideoRef = this.fullscreenBg = null;

    //add base track
    {globalState.baseAudioRef && (
        globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1) //trucks sound        
    )}
  }

  state = {
    textVisible: false,
  }

  
  

  componentDidMount = () => {
    
    setTimeout(()=>{
      this.roadVideoRef.playVideo();
    }, globalState.transitionDuration);

    //position text
    positionText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);
    positionText(this.refs.text2Ref, "right", "top", globalState.textPosition.rightTop);
    

    //show text
    gsap.to(this.refs.text1Ref, 0.15, { autoAlpha: 1.0, delay: 2.0 });
    gsap.to(this.refs.text2Ref, 0.15, { autoAlpha: 1.0, delay: 6.0 });
    
    
    
    //Preload assets from next page
    preloadImages([
      globalState.cdnUrl + preloadData.background, 
      globalState.cdnUrl + preloadData.bookCover, 
      globalState.cdnUrl + preloadData.bookCoverText, 
      globalState.cdnUrl + preloadData.pageLeft, 
      globalState.cdnUrl + preloadData.pageRight, 
      globalState.cdnUrl + preloadData.polaroidFrame, 
      globalState.cdnUrl + preloadData.text0, 
      globalState.cdnUrl + preloadData.text1, 
      globalState.cdnUrl + preloadData.text2, 
      globalState.cdnUrl + preloadData.text3, 
      globalState.cdnUrl + preloadData.text4, 
      globalState.cdnUrl + preloadData.text5, 
      globalState.cdnUrl + preloadData.text6, 
      globalState.cdnUrl + preloadData.text7, 
      globalState.cdnUrl + preloadData.text8, 
      globalState.cdnUrl + preloadData.text9,
      globalState.cdnUrl + preloadData.trucks1,
      globalState.cdnUrl + preloadData.trucks2,
      globalState.cdnUrl + preloadData.icon1Front, 
      globalState.cdnUrl + preloadData.icon1Back,
      globalState.cdnUrl + preloadData.icon2Front, 
      globalState.cdnUrl + preloadData.icon2Back,
      globalState.cdnUrl + preloadData.icon3Front, 
      globalState.cdnUrl + preloadData.icon3Back,
      globalState.cdnUrl + preloadData.icon4Front, 
      globalState.cdnUrl + preloadData.icon4Back,
      globalState.cdnUrl + preloadData.icon5Front, 
      globalState.cdnUrl + preloadData.icon5Back,
      globalState.cdnUrl + preloadData.icon6Front, 
      globalState.cdnUrl + preloadData.icon6Back,
      globalState.cdnUrl + preloadData.icon7Front, 
      globalState.cdnUrl + preloadData.icon7Back,
      globalState.cdnUrl + preloadData.icon8Front, 
      globalState.cdnUrl + preloadData.icon8Back,
      globalState.cdnUrl + preloadData.icon9Front, 
      globalState.cdnUrl + preloadData.icon9Back,
      globalState.cdnUrl + preloadData.icon10Front, 
      globalState.cdnUrl + preloadData.icon10Back
      ], 
       
      this.preloadImgCallback);

  }

  
  preloadImgCallback = () => {
    //this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
    preloadAudio([
        globalState.cdnUrl + preloadData.page1Sound, 
        globalState.cdnUrl + preloadData.page2Sound,
        globalState.cdnUrl + preloadData.page3Sound,
        globalState.cdnUrl + preloadData.pageFlipSound,
        globalState.cdnUrl + preloadData.iconDropSound,
        globalState.cdnUrl + preloadData.iconFlipSound
    ], this.preloadAudioCallback);

  }
  preloadAudioCallback = () => {
      this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }

  

  componentWillUnmount() {
    this.roadVideoRef.pauseVideo();
    
    //Kill any tweens
    gsap.killTweensOf(this.refs.text1Ref);
    gsap.killTweensOf(this.refs.text2Ref);

    //remove base track
    //globalState.baseAudioRef.updateTrack(1, false);
    
  }

  roadVideoEnded = () => {
    console.log("Road Video ended");
    this.roadVideoRef.seekVideo(30);
  }

  toggleTextDisplay = (event) => {

    //toggle text visibility
    this.setState({textVisible: !this.state.textVisible});

    //reset the animation delay
    resetAnimationDelay();

    if(this.state.textVisible){
      //show text
      showText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);
      showText(this.refs.text2Ref, "right", "top", globalState.textPosition.rightTop);
      

    } else {
      //hide text
      hideText(this.refs.text1Ref, "left");
      hideText(this.refs.text2Ref, "right");
    }
  }

  isTransitioning = () =>{
    this.roadVideoRef.pauseVideo();
  }


  render() {

    return (

      <>

        <link rel="prefetch" href="/intro/3" />
        
        <PrevNext globalState={globalState} ref="prevNextRef" 
        nextLocation="/intro/3" prevLocation="/intro/1"
        isTransitioning={this.isTransitioning} />

        {data && (
        <div className="fullpage-wrapper">
          <SEO title="Pine Point - Intro" />

          <div className="text-wrapper">
            <div className="text text--left-top" ref="text1Ref">
              <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginLeft: "0", marginTop: "0" }} onClick={this.toggleTextDisplay} alt="My family left the North when I was about 10. We moved to Regina, and I became that kid who got A’s and F’s, who preferred the sidelines. The guy who didn’t look forward to the day things would change, who took it hard when they did. The guy who talked about the day he’d move to New York. Then, just another guy who moved to Vancouver where, for the most part, things worked out. My name is Mike Simons." />
            </div>
            <div className="text text--right-top" ref="text2Ref">
              <input type="image" src={globalState.cdnUrl + data.text2} style={{ marginRight: "30px", marginTop: "100px" }} onClick={this.toggleTextDisplay} alt="One night last year I went online to see what had become of Pine Point. I’m not sure why. Trying to give shape to a faint bit of nostalgia, I guess. Turns out, Pine Point isn’t there anymore. Really?" />
            </div>
            
          </div>

          {data.roadVideo ? 
            <FullscreenVideo file={globalState.videoUrl + data.roadVideo} 
            poster={globalState.cdnUrl + data.roadVideoPoster} autoPlay={false} loop={false} ended={this.roadVideoEnded} ref={div => this.roadVideoRef = div} fullscreenClass={'fullscreen-video'} globalState={globalState} /> : ''
          }
          
        </div>
        )}
      </>
    );
  }


};

export default IntroPage2
